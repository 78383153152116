<template>
<app-autocomplete :items="items" label="Month"  v-on:input="$emit('input', $event)" v-bind:value="value"/>
</template>

<script>
import AppAutocomplete from "@/components/app/Autocomplete";
export default {
  name: "DropdownListMonth",
  components: {AppAutocomplete},
  props: ['value'],
  computed:{
    items(){
      return Array.from({length: 12}, (item, i) => {
        return {text: new Date(0, i).toLocaleString('en-US', {month: 'long'}), value: i<10? `0${i+1}`: i+1}
      });
    }
  }
}
</script>

<style scoped>

</style>