import { render, staticRenderFns } from "./DropdownListYear.vue?vue&type=template&id=8713f452&scoped=true"
import script from "./DropdownListYear.vue?vue&type=script&lang=js"
export * from "./DropdownListYear.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8713f452",
  null
  
)

export default component.exports