<template>
<app-autocomplete :items="items" label="Year" v-on:input="$emit('input', $event)" v-bind:value="value"/>
</template>

<script>
import AppAutocomplete from "@/components/app/Autocomplete";
export default {
  name: "DropdownListYear",
  components: {AppAutocomplete},
  props: ['value'],
  computed:{
    items() {
      let max = new Date().getFullYear()
      let min = max - 130
      let years = []

      for (var i = max; i >= min; i--) {
        years.push(i)
      }
      return years
    }
  }

}
</script>

<style scoped>

</style>