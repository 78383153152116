<template>
  <v-sheet v-if="activePerson">
    <div class="text-h6">Contact Info</div>
    <v-list-item v-if="!editMobile">
      <v-list-item-icon>
        <v-icon>mdi-phone</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-if="!editMobile">{{ activePerson.mobile }}</v-list-item-title>
        <v-list-item-subtitle v-if="!editMobile">Mobile</v-list-item-subtitle>
        <div v-if="editMobile">
          <app-text-field v-model="activePerson.mobile"/>
        </div>
      </v-list-item-content>
      <v-list-item-action>
        <v-container>
          <v-row align="center"
                 justify="center" v-if="isEditable ">
            <v-col>
              <!--             Reserved for Future visibility of per user item information sharing-->
            </v-col>
            <v-col>
              <app-btn fab @click="editMobile = !editMobile">
                <v-icon>{{ editMobile ? `mdi-content-save` : `mdi-pencil` }}</v-icon>
              </app-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-list-item-action>
    </v-list-item>
    <div v-if="editMobile">
      <app-text-field v-model="activePerson.mobile"/>
      <v-divider/>
      <v-row class="justify-end">
        <v-col>
          <v-btn small @click="editMobile = false">Cancel</v-btn>
          <v-btn small @click="save">Save</v-btn>
        </v-col>
      </v-row>
    </div>
    <v-list-item v-if="!editAddress">
      <v-list-item-icon>
        <v-icon>mdi-home</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-if="activePerson.address">{{ activePerson.city }} {{
            activePerson.county
          }}
        </v-list-item-title>
        <v-list-item-subtitle v-if="!editAddress">Address</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-row align="center"
               justify="center" v-if="isEditable">
          <v-col>
            <!--            Reserved for Future visibility of per user item information sharing-->
          </v-col>
          <v-col>
            <app-btn fab @click="editAddress = !editAddress">
              <v-icon>{{ editAddress ? `mdi-content-save` : `mdi-pencil` }}</v-icon>
            </app-btn>
          </v-col>
        </v-row>
      </v-list-item-action>
    </v-list-item>
    <div v-if="editAddress">
      <app-text-field v-model="activePerson.address" label="Address"/>
      <app-text-field v-model="activePerson.city" label="City/Town"/>
      <app-text-field v-model="activePerson.county" label="County"/>
      <app-text-field v-model="activePerson.postcode" label="Postcode="/>
      <v-divider/>
      <v-row class="justify-end">
        <v-col>
          <v-btn small @click="editAddress = false">Cancel</v-btn>
          <v-btn small @click="save">Save</v-btn>
        </v-col>
      </v-row>
    </div>
    <v-list-item v-if="!editEmail">
      <v-list-item-icon>
        <v-icon>mdi-email</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ activePerson.email }}</v-list-item-title>
        <v-list-item-subtitle>Email</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-row align="center"
               justify="center" v-if="isEditable">
          <v-col>
            <!--            Reserved for Future visibility of per user item information sharing-->
          </v-col>
          <v-col>
            <app-btn fab @click="editEmail = !editEmail">
              <v-icon>{{ editEmail ? `mdi-content-save` : `mdi-pencil` }}</v-icon>
            </app-btn>
          </v-col>
        </v-row>
      </v-list-item-action>
    </v-list-item>
    <div v-if="editEmail">
      <app-text-field v-model="activePerson.email"/>
      <v-divider/>
      <v-row class="justify-end">
        <v-col>
          <v-btn small @click="editEmail = false">Cancel</v-btn>
          <v-btn small @click="save">Save</v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="text-h6">Basic Info {{ activePerson.dateOfBirth }}</div>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-cake</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Not Collected</v-list-item-title>
        <v-list-item-subtitle>Birth Date</v-list-item-subtitle>
        <v-list-item-title>Not Collected</v-list-item-title>
        <v-list-item-subtitle>Birth Year</v-list-item-subtitle>
      </v-list-item-content>
      <v-spacer/>
      <v-list-item-action>
        <v-row align="center"
               justify="center">
          <v-col>
            <!--            Reserved for Future visibility of per user item information sharing-->
          </v-col>
          <v-col>
<!--            <app-btn fab @click="editBirthdayDetails = !editBirthdayDetails">-->
<!--              <v-icon>{{ editBirthdayDetails ? `mdi-content-save` : `mdi-pencil` }}</v-icon>-->
<!--            </app-btn>-->
          </v-col>
        </v-row>
      </v-list-item-action>
    </v-list-item>
    <div v-if="editBirthdayDetails">
      {{dateOfBirthDay}}
      <dropdown-list-day  v-model="dateOfBirthDay" :month="parseInt(dateOfBirthMonth)" :year="parseInt(dateOfBirthYear)" @input="updateDateOfBirth" />
      <dropdown-list-month v-model="dateOfBirthMonth" @input="updateDateOfBirth" />
      <dropdown-list-year v-model="dateOfBirthYear" @input="updateDateOfBirth"/>
      <v-divider/>
      <v-row class="justify-end">
        <v-col>
          <v-btn small @click="editBirthdayDetails = false">Cancel</v-btn>
          <v-btn small @click="save">Save</v-btn>
        </v-col>
      </v-row>
    </div>
  </v-sheet>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import AppBtn from "@/components/app/Btn";
import AppTextField from "@/components/app/TextField";
import DropdownListYear from "@/components/shared/DropdownListYear";
import {formatDate, isAuthorised} from "@/util/helpers";
import DropdownListMonth from "@/components/shared/DropdownListMonth";
import DropdownListDay from "@/components/shared/DropdownListDay";
import {PersonHttp} from "@/http/PersonHttp";
import {AuthHttp} from "@/http/AuthHttp";
import jwt from "jsonwebtoken";


export default {
  name: "ContactAndBasicInfoPage",
  components: {DropdownListDay, DropdownListMonth, DropdownListYear, AppTextField, AppBtn},
  computed: {
    ...mapState("person", ["activePerson", "loggedInPerson"]),
    ...mapState("user",["accountRoles"]),
    dateOfBirthYearFormatted() {
      return this.activePerson.dateOfBirth ? formatDate(this.activePerson.dateOfBirth, 'yyyy') : ''
    },
    dateOfBirthMonthFormatted() {
      return this.activePerson.dateOfBirth ? formatDate(this.activePerson.dateOfBirth, 'mmm') : ''
    },
    dateOfBirthDayFormatted() {
      return this.activePerson.dateOfBirth ? formatDate(this.activePerson.dateOfBirth, 'dd') : ''
    },
    isEditable() {
      return this.activePerson._id === this.loggedInPerson._id || isAuthorised(this.accountRoles,["rootAdmin","admin"])
    }
  },
  data() {
    return {
      editMobile: false,
      editAddress: false,
      editEmail: false,
      editBirthdayDetails: false,
      dateOfBirthYear: '',
      dateOfBirthMonth: '02',
      dateOfBirthDay: '08',
    }
  },
  methods: {
    ...mapMutations("app", ["setToast", "setLoggedIn", "setToken", "setLoggedInUser"]),
    ...mapActions("user", ["setFlyingStatus","setGroupMembership"]),
    async save() {
      try {
        this.editMobile = false
        this.editAddress = false
        this.editEmail = false
        this.editBirthdayDetails = false
        const id = this.activePerson._id
        const data = this.activePerson
        delete data._id
        await PersonHttp.updatePersonDetails(id, data)
        this.activePerson._id = id

        if (this.activePerson._id === this.loggedInPerson._id) {
          const res = await AuthHttp.updateToken()
          // get the decoded payload ignoring signature, no secretOrPrivateKey needed
          await this.setToken(res.token);
          const decoded = jwt.decode(res.token, {complete: true});
          // Update the Users Store
          await this.setLoggedInUser(decoded.payload)
          await this.setGroupMembership(decoded.payload.person.groupMembership)
          await this.setFlyingStatus()
        }
        this.setToast({color: 'success', text: 'Profile update successful', show: true});
      } catch (err) {
        if (err.response) {
          console.log(err.response.data);
        } else {
          console.log(err);
        }
      }
    },
    updateDateOfBirth() {
      this.activePerson.dateOfBirth = new Date(`${this.dateOfBirthDay}/${this.dateOfBirthMonth}/${this.dateOfBirthYear}`)
    }
  },
  mounted() {
    this.dateOfBirthYear = this.activePerson.dateOfBirth ? formatDate(this.activePerson.dateOfBirth, 'yyyy'):''
    this.dateOfBirthMonth = this.activePerson.dateOfBirth ? formatDate(this.activePerson.dateOfBirth, 'MM'):''
    this.dateOfBirthDay = this.activePerson.dateOfBirth ? formatDate(this.activePerson.dateOfBirth, 'dd'):''
  }
}
</script>

<style scoped>

</style>