<template>
  <app-autocomplete :items="items" label="Day" v-on:input="$emit('input', $event)" v-bind:value="value"/>
</template>

<script>
import AppAutocomplete from "@/components/app/Autocomplete";
import {getDaysInMonth} from "@/util/helpers";

export default {
  name: "DropdownListDay",
  components: {AppAutocomplete},
  props: {
    value: {
      default: new Date().getDay()
    },
    month: {
      type: Number,
      default: new Date().getMonth()
    },
    year: {
      type: Number,
      default: new Date().getFullYear()
    }
  },
  data() {
    return {
      items: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]
    }
  },
  watch: {
    month(newVal) {
      this.items = getDaysInMonth(newVal, this.year)
    },
    year(newVal) {
      this.items = getDaysInMonth(this.month, newVal)
    }
  },
  mounted() {
    this.items = getDaysInMonth(this.month, this.year)
  }
}
</script>

<style scoped>

</style>